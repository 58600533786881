@import "variables.scss";

// ==== Spacing ====
$ws-spacers: () !default;
$ws-spacers: map-merge((
  10: (10 / $ws-font-size ),
  15: (15 / $ws-font-size ),
  20: (20 / $ws-font-size),
  30: (30 / $ws-font-size),
  40: (40 / $ws-font-size),
  45: (45 / $ws-font-size),
  50: (50 / $ws-font-size),
  60: (60 / $ws-font-size)
), $ws-spacers);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $ws-spacers {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: #{$length}rem !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: #{$length}rem !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: #{$length}rem !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: #{$length}rem !important;
    }
  }
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $ws-spacers {
    .#{$abbrev}-n#{$size} { #{$prop}: -$length !important; }
    .#{$abbrev}t-n#{$size},
    .#{$abbrev}y-n#{$size} {
      #{$prop}-top: -#{$length}rem !important;
    }
    .#{$abbrev}r-n#{$size},
    .#{$abbrev}x-n#{$size} {
      #{$prop}-right: -#{$length}rem !important;
    }
    .#{$abbrev}b-n#{$size},
    .#{$abbrev}y-n#{$size} {
      #{$prop}-bottom: -#{$length}rem !important;
    }
    .#{$abbrev}l-n#{$size},
    .#{$abbrev}x-n#{$size} {
      #{$prop}-left: -#{$length}rem !important;
    }
  }
}

// ==== Overwriting common ux library ==== //
body {
  font-size: 14px;
  font: Verdana, sans-serif;
}

p {
  color: $ws-black-font;
}

h3 {
  color: $ws-black-font;
}

/* Target Edge */
@supports (-ms-ime-align: auto) {
  // Fix for css line that appears above footer depending on zoom
  footer .footer-curve .footer-curve-bg {
    top: -1px;
    margin-top: 1px;
    border-top: 1px red;
  }
}

/* Target Internet Explorer 11+ fix for css line that appears depending on zoom */
// _:-ms-fullscreen, :root footer .footer-curve .footer-curve-bg { 
//   top: -1px;
// }
// ==== Overwriting common ux library ==== //

.app-heading{
  margin-bottom: 40px;
  padding: 0;
}
.app-sub-heading{
  margin-top:32px;
  margin-bottom: 15px;
  padding: 0 ;
}
.container-radio{
  margin-bottom: 0px;
  &.mb-1{
      margin-bottom: 1px;
  }
}
.label {
  font-weight: bold;
}

.container-min-height {
  min-height: 660px;
}

.form-label {
  color: $ws-black-font;
  font-weight: bold;
}

.input-box {
  width: 280px;
}

.input-box-regular {
  width: auto;
}

.button-row {
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 100%;
}

.btnExitFix {
  position: absolute;
  bottom: 10px;
  right: 0px;
}

.backBtnFix {
  position: absolute;
  bottom: 10px;
  left: 15px;
  width: fit-content;
}

.continueBtnFix {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: fit-content;
}

.booleanFieldFix {
  width: 185px;
}

.homeowner-container {
  padding-bottom: 120px;
  min-height: calc(100vh - 277px); // Magic number so to have no horizontal scroll bar if page doesnt have enough content
  position: relative;
}

.custom-container {
  padding-bottom: 60px;
  min-height: calc(100vh - 277px); // Magic number so to have no horizontal scroll bar if page doesnt have enough content
  position: relative;
}

.btn-orange {
  border: 1px solid $orange;
}

.btn-orange:hover {
  border: 1px solid $orange;
}

.btn-orange:disabled {
  font-weight: normal;
  background: $disabled-grey;
  border: 1px solid $disabled-grey;
}

.btn-blue {
  border: 1px solid #6399ae;
}

.btn-blue:hover {
  border: 1px solid #6399ae;
}

.btn-blue:disabled {
  font-weight: normal;
  background: $disabled-grey;
  border: 1px solid $disabled-grey;
}

.btn {
  width: 132px;
  text-align: center;
}

.container-radio[disabled] {
  cursor: default;
}

.checkmark-disabled {
  border: 1px solid $grey-20;
}

.container-radio:hover input ~ .checkmark.checkmark-disabled {
  border: 1px solid $grey-20;
}

label[disabled] {
  color: $grey-20 !important;
}

label, label[disabled] {
  font-weight: normal;
  height: auto;
}

button:disabled {
  background-color: $disabled-grey;
  border:0;
  color:white;
  font-weight:700px;
  cursor:default;
  text-decoration:none;
}

.optional-tag {
  font-weight: 100;
}
.btn.btn-large-width {
  width: auto;
}

.table-responsive{
  overflow-y: hidden;
}
.fa-file-pdf
{
  font-size: 30px;
  color: #6399AE;
}
.tooltip{
  left: 1px !important;
}
.tooltip-inner {
  padding: 10px !important;
  background-color: #fff !important;
  color: #453F39 !important;
  text-align: left;
  border-radius: 5px;
  border: 2px solid #6399AE;
  box-shadow: 0px 3px 7px 0px #C9C5C1;
  font-size: 14px; 
  white-space: pre-wrap; 
  opacity: 1 !important; 
  z-index: 9999 !important;
  line-height: 16px;
  min-height: auto !important;
  height: auto !important;
  max-height: 100% !important;
  text-align: left !important;
}

// Set max/min width for radio btn column so width will remain consistent across different screen sizes
.radio-btn-col-width {
  width: 40px;
}

// Internet Explorer / Edge does not behave consistently with rows class but no column
.ieFieldWidth {
  width: 330px;
}

.ieExtension .input-box{
  width: 115px;
}

.ireg-font {
  font-size: 16px;
}

// Homeowner Reivew Submit bolding left side of table
.homeowner-container {
  td {
    &:first-child {
      font-weight: bold;
    }
  }
}

// ==== PDF styles ==== //

// Homeowner PDF bolding left side of table
.pdf-container {
  .text-left {
    p {
      font-weight: bold;
      text-align: left;
    }
  }
}

// Pdf overwrite section title css as pdf css is getting overwritten
h2.section-title{
  font-family: Verdana, sans-serif;
  font-size: 1.0rem;
  font-weight: bold;
  color: #ED8B00;
}

.pdf-container {
  text-align: left;
  padding: 0px 30px 0px 0px;
  *, *::before, *::after {
    font-family: Verdana, sans-serif; 
    font-variant-ligatures: normal; // Pdf does not support ligatures: none 
  }
  table.table-dotted tr {
    &:last-child td {
      border-bottom: 0px;
    }
    td {
      border-left: 0px; 
      border-top: 0px;
      border-right: 0px;
      border-bottom: 1px dotted #dee2e6;
      &:first-child {
        border-right: 1px dotted #dee2e6;
      }
    }
  }
}
// ==== PDF styles ==== // 

.wsbc-leaf-inner {
  .bullets{
    ul{
      padding-left: 20px;
      &.ml{
        li{
          &:before{
            margin-left: -2.3em;
            margin-bottom: 0;
          }
        }
      }
      li{
        list-style-position: outside;
        margin-left: 1em;
        line-height: 1.5em;
        &:before{
          margin-left: -1.5em;
          margin-bottom: 0;
        }
      }
    }
  }
}

.default-pointer {
  cursor: default;
}

.cursor-pointer,
.cursor-hand-pointer {
  cursor: pointer;
}

@media(max-width: 320px){
  .button-row{
    .btn{
      width: auto;
    }
  }
  .input-box {
    width: 240px;
  }
}

@media(max-width: 450px){
  .input-box-regular {
        width: 100% !important;
  }
  .modal-dialog{
    left: 6px;
  }
  .modal-footer{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    .btn{
      display: block;
      width: 100% !important;
      margin: 10px 0 !important;
      margin-right: 0px !important;
      &.btn-outline-blue,&.btn-outline-orange {
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
       
      }
    
      &.btn-blue, &.btn-orange {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1;
      }
    }
  }
  .overSizeBtnGroupFix {
    position: relative;
    width: 100%;
  }
  
  .overSizeBtnGroupFix {
    .continueBtnFix,
    .backBtnFix {
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
    .col {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
    #continue {
      margin-top: 10px;
      width: 265px;
    }
    #back {
      width: 265px;
    }
  }
}

.label-disabled {
  color: $disabled-grey;
}

.sunset-orange {
  color: #ed8b00;
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 600px;
  }
}

.fa-file-pdf, .fa-trash-o, .fa-print{     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  overflow: hidden;        
}

.fa-file-pdf:focus, .fa-trash-o:focus, .fa-print:focus {
  border: 1px solid #6399ae;
  background: #e0ebef;
  color: #6399ae;
  text-decoration: none;
}

// UX library code to highlight radio buttons when selected
.container-radio input:focus + .checkmark {border-color: #453F39; box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);}
.container-checkbox input:focus + .checkmark-checkbox {border: 2px solid #453F39; box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25)}

// Iphone-5 button resize
@media screen and (device-aspect-ratio: 40/71) {
  .btn {
    width: 115px;
  }
}

// Fix for backdrop making modals uninteractable
// https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
ngb-modal-backdrop {
  z-index: 1050 !important;
}