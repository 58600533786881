$ws-font-size: 16; // Worksafe uses font-size 14 this variable is for proper conversion from px to rem for responsive

// ==== General Color ====
$black: #000000;
$white: #FFFFFF;
$white-smoke:#F8F8F8;
$orange: #ED8B00;
$sunset-orange: #DC4405;
$grey: #776E64;
$mountain-blue: #6399AE;
$horizon-blue: #59899C;
$pattens-blue: #E2EFF4;
$moss-green: #888D30;
$huckleberry-red: #A4343A;
$yellow-sea: #F1A233;
$sunrise-yellow: #F1BE48;
$raven-grey: #6C757D;
$kabul-grey: #696158;
$disabled-grey: #c9c5c1;
$spring-wood-grey: #E4E2E0;
$light-grey: #D7D7D7;
$cloud-grey: #ADA8A2;
$space-shuttle: #47423C;
$heather-grey: #AEB8C1;
$beige: #E7E8D6;
$blue: #C1D6DF;
$light-orange: #FBE8CC;
$yellow-base: #F1BE48;
$background-blue: #6399AE;
$underline-blue: #a6c5d1;
$white-base: #FFFFFF; 
$ws-black-font: #453F39;

//BLUE
$blue-10: #E0EBEF;
$blue-20: #C1D6DF;
$blue-30: #A1C2CE;
$blue-40: #82ADBE;
$blue-base: #6399AE;
$blue-60: #59899C;
$blue-70: #4F7A8B;
$blue-80: #3B5C68;

// ==== From UX-LIB ====
$grey-20: #C9C5C1;
$black-20: #999999;

// ==== Page Layout ====
$page-width: 1440px;
// ==== Button Color ====
$btn-disable: $light-grey;
$btn-disable-primary: $kabul-grey;
$btn-default-border: #81ACBF;
